import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
})
export class FolderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
