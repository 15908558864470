<div class="card card-custom gutter-b">
  <ng-container *ngIf="requesting">
    <div class="progress progress-modal">
      <div class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar"
        style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </ng-container>

  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        <ng-container *ngIf="model">
          {{ (id ? 'COMMON.EDIT' : 'COMMON.NEW') | translate }}
          <span *ngIf="model && model.id">{{ model.id }} - {{ model.re_expiration_date | date: 'MM/dd/yyyy' }} </span>
        </ng-container>
      </h3>
    </div>
    <div class="card-toolbar">
      <a type="button" class="btn btn-light" routerLink="/evidences">
        <i class="fa fa-arrow-left"></i>{{ 'COMMON.BACK' | translate }}
      </a>
      <button class="btn btn-secondary ml-2" (click)="reset()">
        <i class="fa fa-redo"></i>{{ 'COMMON.RESET' | translate }}
      </button>
    </div>
  </div>
  <div class="card-body">
    <ul class="nav nav-tabs nav-tabs-line" role="tablist">
      <li class="nav-item cursor-pointer" (click)="changeTab(tabs.BASIC_TAB)">
        <a class="nav-link" data-toggle="tab" role="tab" [attr.aria-selected]="activeTabId === tabs.BASIC_TAB"
          [class.active]="activeTabId === tabs.BASIC_TAB">
          {{ 'COMMON.BASIC_INFO' | translate }}
        </a>
      </li>
      <!-- <li *ngIf="model && model.id" class="nav-item cursor-pointer" (click)="changeTab(tabs.PROFILE)">
        <a class="nav-link" data-toggle="tab" role="button" [attr.aria-selected]="activeTabId === tabs.PROFILE"
          [class.active]="activeTabId === tabs.PROFILE">Profile</a>
      </li> -->
    </ul>

    <ng-container *ngIf="activeTabId === tabs.BASIC_TAB">
      <div class="mt-5" *ngIf="model">
        <form action="#" class="form form-label-right" [formGroup]="formGroup">
          <div class="form-group row">
            <div class="col-lg-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'EVIDENCE.FUNCTIONARY' | translate}}</label>
              <app-functionary-autocomplete
              name="functionary" [valid]="isControlValid('functionary')" [touched]="isControlTouched('functionary')" [class.is-invalid]="isControlInvalid('functionary')"
              [class.is-valid]="isControlValid('functionary')" formControlName="functionary" id="functionary" [model]="model.functionary" [disabled]="model && model.id" [addFilters]="[{key: 'user.groups', value: 2}]"></app-functionary-autocomplete>
              <div class="feedback" *ngIf="!isControlTouched('functionary')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'EVIDENCE.FUNCTIONARY' | translate}}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('functionary')">{{ 'EVIDENCE.FUNCTIONARY' | translate}}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'functionary')">{{ 'EVIDENCE.FUNCTIONARY' | translate}}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'functionary')">{{ 'EVIDENCE.FUNCTIONARY' | translate}} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'functionary')">{{ 'EVIDENCE.FUNCTIONARY' | translate}} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 30</div>
            </div>  
            <div class="col-lg-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'EVIDENCE.FOLDER' | translate}}</label>
              <app-folder-autocomplete
              name="folder" [valid]="isControlValid('folder')" [touched]="isControlTouched('folder')" [class.is-invalid]="isControlInvalid('folder')"
              [class.is-valid]="isControlValid('folder')" formControlName="folder" id="folder" [model]="model.folder" [disabled]="model && model.id"></app-folder-autocomplete>
              <div class="feedback" *ngIf="!isControlTouched('folder')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'EVIDENCE.FOLDER' | translate}}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('folder')">{{ 'EVIDENCE.FOLDER' | translate}}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'folder')">{{ 'EVIDENCE.FOLDER' | translate}}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'folder')">{{ 'EVIDENCE.FOLDER' | translate}} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'folder')">{{ 'EVIDENCE.FOLDER' | translate}} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 30</div>
            </div>
            <div class="col-lg-3 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'FOLDER.ACTIVE' | translate}}</label>
              <p-toggleButton class="form-control form-control-lg form-control-solid" formControlName="active" onLabel="{{ 'COMMON.YES' | translate}}" offLabel="{{ 'COMMON.NO' | translate}}" disabled="disabled"></p-toggleButton>
              <div class="feedback" *ngIf="!isControlTouched('active')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'FOLDER.ACTIVE' | translate}}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('active')">{{ 'FOLDER.ACTIVE' | translate}}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'active')">{{ 'FOLDER.ACTIVE' | translate}}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'active')">{{ 'FOLDER.ACTIVE' | translate}} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'active')">{{ 'FOLDER.ACTIVE' | translate}} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-3 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'EVIDENCE.RE_EXPIRATION_DATE' | translate}}</label>
              <p-calendar [inputStyleClass]="getValidClass(isControlValid('re_expiration_date'))" name="re_expiration_date" formControlName="re_expiration_date" disabled="disabled"></p-calendar>
              <div class="feedback" *ngIf="!isControlTouched('re_expiration_date')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'EVIDENCE.RE_EXPIRATION_DATE' | translate}}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('re_expiration_date')">{{ 'EVIDENCE.RE_EXPIRATION_DATE' | translate}}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 're_expiration_date')">{{ 'EVIDENCE.RE_EXPIRATION_DATE' | translate}}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 're_expiration_date')">{{ 'EVIDENCE.RE_EXPIRATION_DATE' | translate}} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 're_expiration_date')">{{ 'EVIDENCE.RE_EXPIRATION_DATE' | translate}} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div> 
            <div class="col-lg-9 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'EVIDENCE.EVIDENCE_LINK' | translate }}</label>
              <input type="text" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="evidence_link" [class.is-invalid]="isControlInvalid('evidence_link')"
                [class.is-valid]="isControlValid('evidence_link')" formControlName="evidence_link" />
              <div class="feedback" *ngIf="!isControlTouched('evidence_link')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'EVIDENCE.EVIDENCE_LINK' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('evidence_link')">{{ 'EVIDENCE.EVIDENCE_LINK' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'evidence_link')">{{ 'EVIDENCE.EVIDENCE_LINK' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'evidence_link')">{{ 'EVIDENCE.EVIDENCE_LINK' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'evidence_link')">{{ 'EVIDENCE.EVIDENCE_LINK' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-3 mb-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'EVIDENCE.QUALIFICATION' | translate }}</label>
              <input type="number" class="form-control form-control-lg form-control-solid" autocomplete="off"
                name="qualification" [class.is-invalid]="isControlInvalid('qualification')"
                [class.is-valid]="isControlValid('qualification')" formControlName="qualification" [readonly]="groupId === 2 ? true : false"/> 
              <div class="feedback" *ngIf="!isControlTouched('qualification')">{{ 'COMMON.PLEASE_ENTER' | translate }} <b>{{ 'EVIDENCE.QUALIFICATION' | translate }}</b></div>
              <div class="valid-feedback" *ngIf="isControlValid('qualification')">{{ 'EVIDENCE.QUALIFICATION' | translate }}  {{ 'COMMON.WAS_ENTERED_CORRECT' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('required', 'qualification')">{{ 'EVIDENCE.QUALIFICATION' | translate }}  {{ 'COMMON.IS_REQUIRED' | translate }}</div>
              <div class="invalid-feedback" *ngIf="controlHasError('minlength', 'qualification')">{{ 'EVIDENCE.QUALIFICATION' | translate }} {{ 'COMMON.SHOULD_HAVE_LEAST' | translate }} 3</div>
              <div class="invalid-feedback" *ngIf="controlHasError('maxLength', 'qualification')">{{ 'EVIDENCE.QUALIFICATION' | translate }} {{ 'COMMON.SHOULD_HAVE_MAXIMUM' | translate }} 100</div>
            </div>
            <div class="col-lg-12">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'EVIDENCE.EVIDENCE_FILE' | translate }}</label>
              <p-fileUpload name="evidence_file" (onSelect)="onSelect($event)" multiple="false" maxFileSize="1000000" [showUploadButton]="false"
              chooseLabel="{{'COMMON.CHOOSE' | translate}}" uploadLabel="{{'COMMON.UPLOAD' | translate}}" cancelLabel="{{'COMMON.CANCEL' | translate}}">
                <ng-template pTemplate="content">
                    <ul *ngIf="files.length > 0">
                        <li *ngFor="let file of files">
                          <span style="width: -webkit-fill-available; height: 35px;" class="label label-lg label-inline label-light-primary" (click)="showFile(file.evidence_file)">
                            <div style="text-align: left; width: 50%;">{{file.name}}</div>
                            <div style="text-align: right; width: 50%;">/ size bytes</div>
                          </span>
                        </li>
                    </ul>
                </ng-template>
              </p-fileUpload>
            </div>
            <div class="col-lg-12 mt-3">
              <label>{{ 'COMMON.ENTER' | translate }} {{ 'EVIDENCE.OBSERVATION' | translate }}</label>
              <p-editor formControlName="observation" [style]="{'height':'150px'}" placeholder="{{ 'EVIDENCE.OBSERVATION' | translate }}"></p-editor>
            </div>
          </div>
          <div class="float-right">
            <a class="btn btn-default font-weight-bold mr-2" routerLink="{{parent}}/evidences">{{ 'COMMON.CANCEL' | translate }}</a>
            <button type="submit" class="btn btn-primary font-weight-bold mr-2"
              [disabled]="formGroup.invalid" (click)="save(false)">{{ 'COMMON.SAVE' | translate }}</button>
            <button type="submit" class="btn btn-primary font-weight-bold mr-2"
              [disabled]="formGroup.invalid" (click)="save(true)">{{ 'COMMON.SAVE_AND_EXIT' | translate }}</button>
          </div>
        </form>
      </div>
    </ng-container>
  </div>
</div>